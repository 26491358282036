@import "normalize";
@import "mixins";

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,400i,700&display=swap");

html,
body {
  //background: #4f75f8;
  background: #f6f7f9;
}

html {
  //overflow: hidden;
  font-family: "Source Sans Pro", sans-serif;
}

.is-clipped {
  position: fixed;
  overflow: hidden !important;
  height: 100% !important;
}

body {
  font-weight: 300;
  font-family: "Source Sans Pro", sans-serif;
  //word-wrap: break-word;
  //font-kerning: normal;
  color: hsla(0, 0%, 0%, 0.7);
}

a {
  font-weight: 300;
  &:hover {
    text-decoration: none;
  }
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

figure {
  margin: 0;
  position: relative;
}

.aspect-ratio {
  position: relative;
  display: block;
  background: #fafbfc;
  padding: 0;

  &:before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // border-bottom: 1px solid rgba(195, 207, 216, 0.3);
  }

  &.isDragging:before {
    border: 1px solid red;
  }
}

.aspect-ratio--square {
  width: 100%;
  padding-bottom: 100%;
  flex-shrink: 0;
}

.aspect-ratio__content {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
